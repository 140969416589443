//  
// * NITE ACCORDION
// To implement the accordion functionality in your HTML, follow these steps:
// 
// 1. Accordion Group Container:
//    Wrap your accordion items in a container with the class `accordion-group`. This container accepts the following data attributes:
// 
//    - `data-active-class`: Specifies the class or classes to apply when an accordion item is active (open). For example, `data-active-class="text-blue-500 underline uppercase"` will apply these classes to the active accordion item.
//    
//    - `data-inactive-class`: Specifies the class or classes to apply when an accordion item is inactive (closed). For example, `data-inactive-class="text-gray-600 lowercase"` will apply these classes to the inactive accordion item.
// 
//    - `data-auto-close`: If set to `true`, only one accordion item can be open at a time. Other open items will automatically close when a new item is opened.
// 
// 2. Accordion Item Container:
//    Each accordion item should be enclosed in a container with the class `accordion-item`. Inside this container, use the class `accordion-section` for each individual accordion item. This container accepts the following data attributes:
// 
//    - `data-open`: Set to `true` if you want the accordion item to be open by default when the page loads.
// 
//    - `data-rotate`: Specifies the rotation value for the toggle icon. For example, `data-rotate="45"` will rotate the icon by 45 degrees.
// 
// 3. Accordion Button and Content:
//    Inside each `accordion-section`, create a button with the class `accordion-heading` for the accordion title. Additionally, include a container with the class `accordion-content` for the content that should be revealed or hidden.
// 
//    - Button:
//      - Include a `<button>` element for the accordion title. Clicking this button will toggle the accordion item.
// 
//    - Content:
//      - Place your content inside a `<div>` with the class `accordion-content`. This content will be hidden or revealed when the accordion item is toggled.
// 
// Remember to include the provided JavaScript code in your project to enable the accordion functionality. You can customize the appearance and behavior of each accordion item using the specified data attributes and classes.
// 



// Function to toggle accordion item visibility
function toggleAccordion(accordionSection) {
    const button = accordionSection.querySelector('.accordion-section button');
    const heading = button.querySelector('.accordion-heading');
    const icon = accordionSection.querySelector('.accordion-icon');
    const content = accordionSection.querySelector('.accordion-content');
    const isOpen = accordionSection.getAttribute('data-open') === 'true';
    const rotateValue = accordionSection.getAttribute('data-rotate') || '45';
    const accordionGroup = accordionSection.closest('.accordion-group');
    const activeClass = accordionGroup.getAttribute('data-active-class');
    const inactiveClass = accordionGroup.getAttribute('data-inactive-class');
    const autoClose = accordionGroup.getAttribute('data-auto-close') === 'true';

    // Set initial state based on data attributes
    content.style.display = isOpen ? 'block' : 'none';
    icon.classList.toggle('rotate-' + rotateValue, isOpen);

    if (activeClass) {
        activeClass.split(' ').forEach(className => {
            heading.classList.toggle(className, isOpen);
        });
    }

    if (inactiveClass) {
        inactiveClass.split(' ').forEach(className => {
            heading.classList.toggle(className, !isOpen);
        });
    }

    button.addEventListener('click', () => {
        if (autoClose) {
            // Close other accordion items if auto-close is enabled
            const allAccordionSections = accordionGroup.querySelectorAll('.accordion-section');
            allAccordionSections.forEach(section => {
                if (section !== accordionSection) {
                    section.setAttribute('data-open', 'false');
                    section.querySelector('.accordion-content').style.display = 'none';
                    section.querySelector('.accordion-icon').classList.remove('rotate-' + rotateValue);

                    if (activeClass) {
                        activeClass.split(' ').forEach(className => {
                            section.querySelector('.accordion-heading').classList.remove(className);
                        });
                    }

                    if (inactiveClass) {
                        inactiveClass.split(' ').forEach(className => {
                            section.querySelector('.accordion-heading').classList.add(className);
                        });
                    }
                }
            });
        }

        if (content.style.display === 'none' || content.style.display === '') {
            content.style.display = 'block';
            icon.classList.add('rotate-' + rotateValue);

            if (activeClass) {
                heading.classList.add(...activeClass.split(' '));
                heading.classList.remove(...inactiveClass.split(' '));
            }
        } else {
            content.style.display = 'none';
            icon.classList.remove('rotate-' + rotateValue);

            if (inactiveClass) {
                heading.classList.remove(...activeClass.split(' '));
                heading.classList.add(...inactiveClass.split(' '));
            }
        }
    });
}

// Toggle each accordion item
document.querySelectorAll('.accordion-section').forEach(toggleAccordion);

// <!-- Sample HTML Structure: -->
// <div class="accordion-group border border-gray-200 rounded-lg p-3"
//      data-active-class="text-blue-500 underline uppercase"
//      data-inactive-class="text-gray-600 lowercase"
//      data-auto-close="true">
     
//     <!-- Accordion Item 1 -->
//     <div class="accordion-item">
//         <div class="accordion-section" data-open="true" data-rotate="45">
//             <button class="accordion-heading w-full flex justify-between items-center p-3">
//                 <h3 class="text-sm font-semibold">Accordion Title 1</h3>
//                 <div>
//                     <span class="accordion-icon text-lg transition-all block">+</span>
//                 </div>
//             </button>
//             <div class="accordion-content text-sm text-black/50 p-3 hidden">
//                 <!-- Content for Accordion Item 1 -->
//                 Content goes here.
//             </div>
//         </div>
//     </div>

//     <!-- Accordion Item 2 -->
//     <div class="accordion-item">
//         <!-- ... Repeat structure for additional accordion items ... -->
//     </div>

// </div>