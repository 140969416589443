class niteDropdown {
    constructor(selector) {
        if (selector.startsWith('#')) {
            // Handle ID selector
            this.dropdown = document.getElementById(selector.slice(1)); // Select the element by ID

            if (this.dropdown) {
                this.initializeDropdown(this.dropdown);
            }
        } else if (selector.startsWith('.')) {
            // Handle class selector
            this.dropdowns = document.querySelectorAll(selector); // Select elements by class

            this.dropdowns.forEach((dropdown) => {
                this.initializeDropdown(dropdown);
            });
        } else {
            throw new Error('Invalid selector. Use either an ID (e.g., "#action-menu") or a class (e.g., ".action-menu").');
        }

        if (this.dropdown) {
            // Additional common logic for both ID and class selectors can go here
            window.addEventListener('resize', () => {
                this.closeAll();
            });
        }
    }

    initializeDropdown(dropdown) {
        const toggleButton = dropdown.querySelector('.nite-select-toggle');
        const menu = dropdown.querySelector('.nite-select-menu');
        const config = this.extractDataAttributes(dropdown);

        // Event listeners
        toggleButton.addEventListener('click', () => this.toggle(dropdown));
        toggleButton.addEventListener('mouseover', () => this.handleMouseOver(dropdown));
        menu.addEventListener('mouseleave', () => this.handleMouseLeave(dropdown));

        // Store configuration options
        dropdown.niteSelectConfig = config;
        dropdown.isOpen = false;
        dropdown.popperInstance = null;

        // document.addEventListener('keydown', (event) => this._handleKeyPress(event, dropdown));

        // Event listener to close the dropdown when clicking outside of it
        document.addEventListener("click", (event) => {
            const clickableAttribute = menu.getAttribute("data-clickable");
            const isClickable = clickableAttribute && clickableAttribute.toLowerCase() === "true";

            if (!toggleButton.contains(event.target) && !toggleButton.contains(event.target) && !isClickable) {
                this.close(dropdown);
            }
        });
    }

    extractDataAttributes(dropdown) {
        const data = dropdown.dataset;
        return {
            placement: data.placement || 'bottom-start',
            strategy: data.strategy || 'fixed',
            adaptive: data.adaptive || 'adaptive',
            offset: parseInt(data.offset) || 10,
            trigger: data.trigger || 'hover',
            autoClose: data.autoClose || 'inside',
        };
    }

    open(dropdown) {
        const menu = dropdown.querySelector('.nite-select-menu');
        const placement = dropdown.dataset.placement || 'bottom-start';
        const strategy = dropdown.dataset.strategy || 'fixed';
        const adaptive = dropdown.dataset.adaptive || 'fixed';
        const offset = parseInt(dropdown.dataset.offset) || 10;

        const toggleButton = dropdown.querySelector('.nite-select-toggle');
        const config = dropdown.niteSelectConfig;

        if (strategy !== 'static') {
            if (dropdown.popperInstance) {
                dropdown.popperInstance.destroy();
            }

            dropdown.popperInstance = Popper.createPopper(dropdown, menu, {
                placement: config.placement,
                strategy: strategy,
                modifiers: [
                    ...(strategy !== 'fixed' ? this.absoluteStrategyModifiers(dropdown) : []),
                    {
                        name: 'offset',
                        options: {
                            offset: [0, offset],
                        },
                    },
                ],
            });
        }

        menu.style.margin = null;
        menu.classList.add('block');
        menu.classList.remove('hidden');
        dropdown.isOpen = true;

        setTimeout(() => {
            dropdown.classList.add('open');
        });
    }

    close(dropdown) {
        if (!dropdown.isOpen) return;
        const menu = dropdown.querySelector('.nite-select-menu');

        if (dropdown.popperInstance) {
            dropdown.popperInstance.destroy();
        }

        menu.style.margin = null;
        menu.classList.remove('block');
        menu.classList.add('hidden');
        dropdown.isOpen = false;
    }

    toggle(dropdown) {
        if (dropdown.isOpen) {
            this.close(dropdown);
        } else {
            this.open(dropdown);
        }
    }

    handleMouseOver(dropdown) {
        const config = dropdown.niteSelectConfig;
        if (config.trigger === 'hover') {
            this.open(dropdown);
        }
    }

    handleMouseLeave(dropdown) {
        const config = dropdown.niteSelectConfig;
        if (config.trigger === 'hover' && config.autoClose !== 'outside') {
            this.close(dropdown);
        }
    }

    closeAll() {
        this.dropdowns.forEach((dropdown) => {
            this.close(dropdown);
        });
    }

    // _handleKeyPress(event, dropdown) {
    //     const toggleButton = dropdown.querySelector('.nite-select-toggle');
    //     const menu = dropdown.querySelector('.nite-select-menu');
    //     const config = dropdown.niteSelectConfig;

    //     const $menuItems = Array.from(menu.querySelectorAll('a, button, div'));
    //     const activeItem = $menuItems.find((item) => item === document.activeElement);
    //     let newItem;

    //     switch (event.which) {
    //         case 27: // escape
    //             this.close(dropdown);
    //             break;
    //         case 40: // down arrow
    //             newItem = $menuItems[$menuItems.indexOf(activeItem) + 1];
    //             break;
    //         case 38: // up arrow
    //             newItem = $menuItems[$menuItems.indexOf(activeItem) - 1];
    //             break;
    //         case 36: // home
    //             newItem = $menuItems[0];
    //             break;
    //         case 35: // end
    //             newItem = $menuItems[$menuItems.length - 1];
    //             break;
    //         default:
    //             newItem = $menuItems.find((item) => {
    //                 const itemText = item.textContent.trim(); // Remove leading/trailing spaces
    //                 const lowerCaseItemText = itemText.toLowerCase();
    //                 const lowerCaseEventKey = event.key.toLowerCase();
    //                 return lowerCaseItemText.startsWith(lowerCaseEventKey);
    //             });
    //             // newItem = $menuItems.find((item) =>
    //             //     item.textContent.toLowerCase().startsWith(event.key.toLowerCase())
    //             // );
    //     }

    //     if (newItem) {
    //         newItem.focus();
    //         event.preventDefault(); // Prevent the default behavior of the arrow keys
    //     }
    // }

    handleClickOutside(event, dropdown) {
        const toggleButton = dropdown.querySelector('.nite-select-toggle');
        const menu = dropdown.querySelector('.nite-select-menu');
        const config = dropdown.niteSelectConfig;
        
        // Check if the event target is not inside the dropdown or its children
        if (
            event.target !== toggleButton &&
            event.target !== menu &&
            !menu.contains(event.target)
        ) {
            if (config.trigger === 'click') {
                this.close(dropdown);
            }
        }
    }
}

export default niteDropdown





// class NiteDropdown {
//     constructor(dropdownElement) {
//         this.dropdownElement = dropdownElement;
//         this.buttonTrigger = dropdownElement.querySelector('.niteDropdown_button_trigger');
//         this.menu = dropdownElement.querySelector('.niteDropdown_menu');
//         this.menuOptions = dropdownElement.querySelector('.niteDropdown_menu_options');
//         this.menuSpinner = dropdownElement.querySelector('.niteDropdown_menu_spinner');

//         // Attach click event listener to the button trigger
//         this.buttonTrigger.addEventListener('click', () => {
//             this.toggleDropdown();
//         });

//         // Attach blur event listener to close the dropdown when focus is lost outside the dropdownElement
//         document.addEventListener('click', (event) => {
//             if (!this.dropdownElement.contains(event.target)) {
//                 this.closeDropdown();
//             }
//         });

//         // Set initial aria attributes
//         this.buttonTrigger.setAttribute('aria-expanded', 'false');
//         this.menu.setAttribute('aria-hidden', 'true');
//     }

//     /**
//      * Open a dropdown when the button is clicked or receives focus via 'tab' on the keyboard.
//      * @param {Event} event - The click or focus event.
//      */
//     openDropdown() {
//         this.menu.classList.remove('hidden');
//         this.buttonTrigger.setAttribute('aria-expanded', 'true');
//         this.position();
//         // Call toggleOptionAndSpinner to set the initial state
//         this.toggleOptionAndSpinner();
//     }

//     /**
//      * Close the dropdown.
//      */
//     closeDropdown() {
//         this.menu.classList.add('hidden');
//         this.buttonTrigger.setAttribute('aria-expanded', 'false');
//     }

//     /**
//      * Toggle the dropdown open or closed when the button is clicked.
//      */
//     toggleDropdown() {
//         if (this.menu.classList.contains('hidden')) {
//             this.openDropdown();
//         } else {
//             this.closeDropdown();
//         }
//     }

//     /**
//      * Show the dropdown options.
//      */
//     showOptions() {
//         this.menuOptions.classList.remove('hidden');
//         this.menuSpinner.classList.add('hidden');
//     }

//     /**
//      * Show the loading spinner.
//      */
//     showSpinner() {
//         this.menuSpinner.classList.remove('hidden');
//         // this.menuOptions.classList.add('hidden');
//     }

//     /**
//      * Toggle between showing options and the loading spinner based on menuOptions content.
//      */
//     toggleOptionAndSpinner() {
//         const childNodes = this.menuOptions.childNodes;
//         const hasOptions = Array.from(childNodes).some(node => {
//             return node.nodeType === Node.ELEMENT_NODE; // Check for element nodes
//         });

//         if (hasOptions) {
//             this.showOptions();
//         } else {
//             this.showSpinner();
//         }
//     }

//     /**
//      * Position the dropdown using Popper.js.
//      */
//     position() {
//         // Get data attributes for positioning
//         const strategy = this.menu.getAttribute('data-strategy');
//         const offset = parseInt(this.menu.getAttribute('data-offset')) || 10;
//         const placement = this.menu.getAttribute('data-placement') || 'bottom-start';

//         // Calculate position and use Popper.js to place the dropdown
//         const popper = Popper.createPopper(this.buttonTrigger, this.menu, {
//             strategy,
//             modifiers: [
//                 {
//                     name: 'offset',
//                     options: {
//                         offset: [0, offset],
//                     },
//                 },
//             ],
//             placement,
//         });
//     }
// }

// // Usage: Initialize NiteDropdown on elements with the 'niteDropdown_wrapper' class
// const dropdowns = document.querySelectorAll('.niteDropdown_wrapper');
// document.addEventListener('DOMContentLoaded', function () {
//     dropdowns.forEach((dropdown) => {
//         new NiteDropdown(dropdown);
//     });
// });



// * Working Basic Version of NiteDropdown class
// class NiteDropdown {
//     constructor(dropdownElement) {
//         this.dropdownElement = dropdownElement;
//         this.buttonTrigger = dropdownElement.querySelector('.niteDropdown_button_trigger');
//         this.menu = dropdownElement.querySelector('.niteDropdown_menu');
//         this.menuOptions = dropdownElement.querySelector('.niteDropdown_menu_options');
//         this.menuSpinner = dropdownElement.querySelector('.niteDropdown_menu_spinner');

//         // Attach click event listener to the button trigger
//         this.buttonTrigger.addEventListener('click', () => {
//             this.toggleDropdown();
//         });

//         // Set initial aria attributes
//         this.buttonTrigger.setAttribute('aria-expanded', 'false');
//         this.menu.setAttribute('aria-hidden', 'true');
//     }

//     openDropdown() {
//         this.menu.classList.remove('hidden');
//         this.buttonTrigger.setAttribute('aria-expanded', 'true');
//         this.position();
//         // Call toggleOptionAndSpinner to set the initial state
//         this.toggleOptionAndSpinner();
//     }

//     closeDropdown() {
//         this.menu.classList.add('hidden');
//         this.buttonTrigger.setAttribute('aria-expanded', 'false');
//     }

//     toggleDropdown() {
//         if (this.menu.classList.contains('hidden')) {
//             this.openDropdown();
//         } else {
//             this.closeDropdown();
//         }
//     }

//     showOptions() {
//         this.menuOptions.classList.remove('hidden');
//         this.menuSpinner.classList.add('hidden');
//     }

//     showSpinner() {
//         this.menuSpinner.classList.remove('hidden');
//         this.menuOptions.classList.add('hidden');
//     }

//     toggleOptionAndSpinner() {
//         const hasOptions = this.menuOptions.hasChildNodes();

//         if (hasOptions) {
//             this.showOptions();
//         } else {
//             this.showSpinner();
//         }
//     }

//     position() {
//         // Get data attributes for positioning
//         const strategy = this.menu.getAttribute('data-strategy');
//         const offset = parseInt(this.menu.getAttribute('data-offset')) || 0;
//         const placement = this.menu.getAttribute('data-placement');

//         // Calculate position and use Popper.js to place the dropdown
//         const popper = Popper.createPopper(this.buttonTrigger, this.menu, {
//             strategy,
//             modifiers: [
//                 {
//                     name: 'offset',
//                     options: {
//                         offset: [0, offset],
//                     },
//                 },
//             ],
//             placement,
//         });
//     }
// }

// // Usage: Initialize NiteDropdown on elements with the 'niteDropdown_wrapper' class
// const dropdowns = document.querySelectorAll('.niteDropdown_wrapper');
// dropdowns.forEach((dropdown) => {
//     new NiteDropdown(dropdown);
// });






// * Basic dropdown code
// const dropdownButton = document.getElementById("dropdown_button");
// const dropdownMenu = document.getElementById("dropdown_menu");

// openDropdown();

// // Function to close the dropdown
// function closeDropdown() {
//     dropdownButton.setAttribute("aria-expanded", "false");
//     dropdownMenu.classList.remove("transform", "scale-100", "opacity-100", "transition-ease-in", "duration-75");
//     dropdownMenu.classList.add("transform", "scale-95", "opacity-0", "transition-ease-out", "duration-100");
//     setTimeout(() => {
//         dropdownMenu.classList.add("hidden");
//     }, 100); // Wait for the animation to complete before hiding the menu
// }

// function openDropdown() {
//     clearTimeout(scrollTimeout);

//     // Delay positioning calculations and toggle visibility
//     scrollTimeout = setTimeout(function () {
//         // const dropdown = document.getElementById(dropdownID);
//         const popper = Popper.createPopper(dropdownButton, dropdownMenu, {
//             placement: 'right',
//             modifiers: [{
//                 name: 'offset',
//                 options: {
//                     offset: [0, 20], // Adjust the offset as needed
//                 },
//             },],
//         });
//     }, 100); // Adjust the delay (in milliseconds) as needed
// }

// // Event listener to close the dropdown when clicking outside of it
// document.addEventListener("click", (event) => {
//     if (!dropdownButton.contains(event.target) && !dropdownMenu.contains(event.target)) {
//         closeDropdown();
//     }
// });

// // Event listener to close other dropdowns when clicking on one
// const allDropdownButtons = document.querySelectorAll("[aria-haspopup='true']");
// allDropdownButtons.forEach((button) => {
//     button.addEventListener("click", (event) => {
//         if (button !== dropdownButton) {
//             // Close other open dropdowns
//             const otherDropdownMenu = document.getElementById(button.getAttribute("aria-controls"));
//             if (otherDropdownMenu) {
//                 otherDropdownMenu.classList.add("hidden");
//             }
//         }
//     });
// });

// // Event listener for clicking the dropdown button
// dropdownButton.addEventListener("click", () => {
//     const isExpanded = dropdownButton.getAttribute("aria-expanded") === "false";

//     if (isExpanded) {
//         dropdownButton.setAttribute("aria-expanded", "true");
//         dropdownMenu.classList.remove("hidden");
//         setTimeout(() => {
//             dropdownMenu.classList.remove("transform", "scale-95", "opacity-0",
//                 "transition-ease-out", "duration-100");
//             dropdownMenu.classList.add("transform", "scale-100", "opacity-100",
//                 "transition-ease-in", "duration-75");
//         }, 0); // Delay adding animation classes to ensure they trigger the animation
//     } else {
//         closeDropdown();
//     }
// });