import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import niteTab from '../../public/assets/js/tab';
window.niteTab = niteTab;

import NiteSelect from '../../public/assets/js/select.js';
window.NiteSelect = NiteSelect;

import niteDropdown from '../../public/assets/js/dropdown';
window.niteDropdown = niteDropdown;

import '../../public/assets/js/accordion.js';

import * as Popper from "@popperjs/core"
window.Popper = Popper;

import { createPopper } from "@popperjs/core/lib/createPopper";
window.createPopper = createPopper;

import money from './money.js';
window.money = money;

// Complete SortableJS (with all plugins)
import Sortable from 'sortablejs/modular/sortable.complete.esm';
window.Sortable = Sortable;
