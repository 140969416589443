import './bootstrap';

import Alpine from 'alpinejs';
import { collapse } from '@alpinejs/collapse';
Alpine.plugin(collapse);

window.Alpine = Alpine;

Alpine.start();

// Import Iodine for form validation
import Iodine from '@caneara/iodine';

// Import IMask for form input masking
import IMask from 'imask';

import tippy from "tippy.js"; // Import the tippy function

// Import other necessary modules
import { delegate } from "tippy.js";
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-toward-subtle.css';

// Regular tooltips configuration
const regularTooltip = {
    interactive: true,
    allowHTML: true,
    animation: 'shift-toward-subtle',
    content(reference) {
        const tooltipText = reference.getAttribute('tooltip');
        return tooltipText || '';
    },
    placement: 'top',
    arrow: true,
    theme: 'tippy-box',
    ignoreAttributes: true,
    hideOnClick: true,
    delay: 5,
    offset: [0, 20],
    maxWidth: 200,
    trigger: 'mouseenter',
    zIndex: 20,
    touch: ['hold', 200],
    popperOptions: {
        modifiers: [
            {
                name: 'flip',
                options: {
                    fallbackPlacements: ['right', 'left', 'bottom']
                }
            }
        ]
    },
    appendTo: document.body,
    onMount(instance) {
        instance.popper.querySelector('.tippy-box').setAttribute('role', 'tooltip');
        instance.popper.querySelector('.tippy-box').setAttribute('tabindex', '0');
    }
};

// Tooltip with HTML content configuration
const htmlTooltip = {
    ...regularTooltip,
    content(reference) {
        const tooltipHTML = reference.getAttribute('tooltip-html');
        return tooltipHTML || '';
    },
    maxWidth: null, // No maxWidth for tooltips with HTML content
    onCreate(instance) {
        // Set padding to 0 for .tippy-content within the tooltip
        instance.popper.querySelector('.tippy-content').style.padding = '0';

        // Optionally, if you need to handle background colors or other styles dynamically
        // const backgroundClass = reference.getAttribute('data-tooltip-html-background');
        // if (backgroundClass) {
        //     instance.popper.querySelector('.tippy-box').classList.add(backgroundClass);
        // }
    }
    // * If required to use Tailwindcss BG classes then uncomment the below code - untested
    // * USAGE: <div tooltip-html="Some HTML content" data-tooltip-html-background="bg-blue-500"></div>
    // onCreate(instance) {
    //     // Add custom class to the tooltip element based on the data-background attribute
    //     const backgroundClass = reference.getAttribute('data-tooltip-html-background');
    //     if (backgroundClass) {
    //         instance.popper.querySelector('.tippy-box').classList.add(backgroundClass);
    //     }
    // }
};

// Create instances for regular tooltips and tooltips with HTML content
tippy('[tooltip]', regularTooltip);
tippy('[tooltip-html]', htmlTooltip);