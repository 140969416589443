class niteTab {
    constructor(containerId) {
        this.container = document.getElementById(containerId);
        this.innerContainer = this.container.querySelector('.data-container');
        this.tabLinks = this.container.querySelectorAll('.tab-link');
        this.tabContent = this.container.querySelectorAll('.tab-pane');

        // Get the data attributes from the inner container element
        const containerData = this.innerContainer.dataset;
        this.activeTab = containerData.activeTab || this.activeTab;
        this.activeClass = containerData.activeClass;
        this.inactiveClass = containerData.inactiveClass;

        this.init();
    }

    init() {
        this.tabLinks.forEach((link, index) => {
            link.addEventListener('click', () => this.handleTabClick(link, index));
            // Set ARIA attributes for each tab link
            link.setAttribute('role', 'tab');
            link.setAttribute('aria-selected', index === 0 ? 'true' : 'false');
            link.setAttribute('tabindex', index === 0 ? '0' : '-1');
        });

        if (window.location.hash) {
            const initialTab = document.querySelector(window.location.hash);
            if (initialTab) {
                this.showTab(window.location.hash);
            }
        } else {
            this.showTab(this.activeTab);
        }
    }

    showTab(tabId) {
        // Use the data-active-tab attribute if no hash is present
        if (!tabId && this.activeTab) {
            tabId = this.activeTab;
        }

        this.tabContent.forEach(content => {
            content.classList.add('hidden'); // Hide content using Tailwind CSS class
        });

        this.tabLinks.forEach(link => {
            const tabLinkDataTab = link.getAttribute('data-tab');
            if (tabLinkDataTab === tabId) {
                link.classList.remove(...this.inactiveClass.split(' '));
                link.classList.add(...this.activeClass.split(' '));
                link.setAttribute('aria-selected', 'true');
                link.setAttribute('tabindex', '0');
            } else {
                link.classList.remove(...this.activeClass.split(' '));
                link.classList.add(...this.inactiveClass.split(' '));
                link.setAttribute('aria-selected', 'false');
                link.setAttribute('tabindex', '-1');
            }
        });

        const innerTabContainer = document.querySelector(tabId);
        if (innerTabContainer) {
            innerTabContainer.classList.remove('hidden'); // Show inner tab content using Tailwind CSS class
            innerTabContainer.querySelectorAll('.tab-pane').forEach(content => {
                content.classList.add('hidden'); // Hide inner tab content by default
            });
            const activeInnerTabLink = innerTabContainer.querySelector('[aria-selected="true"]');
            if (activeInnerTabLink) {
                const innerTabId = activeInnerTabLink.getAttribute('data-tab');
                const innerTabContent = innerTabContainer.querySelector(innerTabId);
                if (innerTabContent) {
                    innerTabContent.classList.remove('hidden'); // Show active inner tab content
                }
            }
        }
    }

    handleTabClick(link, index) {
        const tabId = link.getAttribute('data-tab');
        this.showTab(tabId);
        const baseUrl = window.location.origin + window.location.pathname;
        window.history.replaceState({}, '', `${baseUrl}${tabId}`);
        // link.focus();
    }
}

export default niteTab;













// * works as desired just need to update it to accept url entries
// class niteTab {
//     constructor(containerId) {
//         this.container = document.getElementById(containerId);
//         this.innerContainer = this.container.querySelector('.data-container');
//         this.tabLinks = this.container.querySelectorAll('.tab-link');
//         this.tabContent = this.container.querySelectorAll('.tab-pane');

//         // Get the data attributes from the inner container element
//         const containerData = this.innerContainer.dataset;
//         this.activeTab = containerData.activeTab;
//         this.activeClass = containerData.activeClass;
//         this.inactiveClass = containerData.inactiveClass;

//         this.init();
//     }

//     init() {
//         this.tabLinks.forEach((link, index) => {
//             link.addEventListener('click', () => this.handleTabClick(link, index));
//             // Set ARIA attributes for each tab link
//             link.setAttribute('role', 'tab');
//             link.setAttribute('aria-selected', index === 0 ? 'true' : 'false');
//             link.setAttribute('tabindex', index === 0 ? '0' : '-1');
//         });

//         if (window.location.hash) {
//             const initialTab = document.querySelector(window.location.hash);
//             if (initialTab) {
//                 this.showTab(window.location.hash);
//             }
//         } else {
//             this.showTab(this.activeTab);
//         }
//     }

//     showTab(tabId) {
//         this.tabContent.forEach(content => {
//             content.classList.add('hidden'); // Hide content using Tailwind CSS class
//         });

//         this.tabLinks.forEach(link => {
//             const tabLinkDataTab = link.getAttribute('data-tab');
//             if (tabLinkDataTab === tabId) {
//                 link.classList.remove(...this.inactiveClass.split(' '));
//                 link.classList.add(...this.activeClass.split(' '));
//                 link.setAttribute('aria-selected', 'true');
//                 link.setAttribute('tabindex', '0');
//             } else {
//                 link.classList.remove(...this.activeClass.split(' '));
//                 link.classList.add(...this.inactiveClass.split(' '));
//                 link.setAttribute('aria-selected', 'false');
//                 link.setAttribute('tabindex', '-1');
//             }
//         });

//         const innerTabContainer = document.querySelector(tabId);
//         if (innerTabContainer) {
//             innerTabContainer.classList.remove('hidden'); // Show inner tab content using Tailwind CSS class
//             innerTabContainer.querySelectorAll('.tab-pane').forEach(content => {
//                 content.classList.add('hidden'); // Hide inner tab content by default
//             });
//             const activeInnerTabLink = innerTabContainer.querySelector('[aria-selected="true"]');
//             if (activeInnerTabLink) {
//                 const innerTabId = activeInnerTabLink.getAttribute('data-tab');
//                 const innerTabContent = innerTabContainer.querySelector(innerTabId);
//                 if (innerTabContent) {
//                     innerTabContent.classList.remove('hidden'); // Show active inner tab content
//                 }
//             }
//         }
//     }

//     handleTabClick(link, index) {
//         const tabId = link.getAttribute('data-tab');
//         this.showTab(tabId);
//         const baseUrl = window.location.origin + window.location.pathname;
//         window.history.replaceState({}, '', `${baseUrl}${tabId}`);
//         link.focus();
//     }
// }

// export default niteTab;















// class niteTab {
//     constructor(selector) {
//         this.tab = document.querySelector(selector);
//         this.tabButtons = this.tab.querySelectorAll('[role="tab"]');
//         this.tabPanels = Array.from(this.tab.querySelectorAll('[role="tabpanel"]'));

//         // Add event listeners to tab buttons
//         this.tabButtons.forEach(button => {
//             button.addEventListener('click', this.tabClickHandler.bind(this));
//         });

//         // Check for URL parameter and set current tab accordingly
//         const urlTabId = this.getCurrentTabFromURL();
//         const activeTabId = this.tab.getAttribute('data-active-tab');

//         if (urlTabId) {
//             this.showTabById(urlTabId);
//         } else if (activeTabId) {
//             this.showTabById(activeTabId);
//         } else {
//             this.tabButtons[0].click(); // Show the first tab
//         }
//     }

//     tabClickHandler(e) {
//         // Hide All Tabpanes
//         this.tabPanels.forEach(panel => {
//             panel.hidden = true;
//         });

//         // Deselect Tab Button
//         this.tabButtons.forEach(button => {
//             button.setAttribute('aria-selected', 'false');
//         });

//         // Mark New Tab
//         e.currentTarget.setAttribute('aria-selected', 'true');

//         // Show New Tab
//         const { id } = e.currentTarget;
//         this.showTabById(id);

//         // Update URL parameter
//         const baseUrl = window.location.origin + window.location.pathname.split('#')[0]; // Extract base URL without tab identifier
//         const newUrl = baseUrl + '#' + id; // Construct new URL
//         window.history.replaceState({}, '', newUrl);
//     }

//     showTabById(tabId) {
//         const currentTab = this.tabPanels.find(
//             panel => panel.getAttribute('aria-labelledby') === tabId
//         );

//         if (currentTab) {
//             currentTab.hidden = false;
//         }
//     }

//     getCurrentTabFromURL() {
//         const path = window.location.pathname.split('#')[1]; // Extract tab identifier after '#'
//         if (path) {
//             const button = this.tab.querySelector(`[id="${path}"]`);
//             if (button) {
//                 return path;
//             }
//         }
//         return null;
//     }
// }

// export default niteTab;


















// * works
// class niteTab {
//     constructor(selector) {
//         this.tab = document.querySelector(selector);
//         this.tabButtons = this.tab.querySelectorAll('[role="tab"]');
//         this.tabPanels = Array.from(this.tab.querySelectorAll('[role="tabpanel"]'));

//         // Add event listeners to tab buttons
//         this.tabButtons.forEach(button => {
//             button.addEventListener('click', this.tabClickHandler.bind(this));
//         });

//         // Check for URL parameter and set current tab accordingly
//         this.setCurrentTabFromURL();

//         // Check for data-active-tab attribute and set initial tab accordingly
//         const activeTabId = this.tab.getAttribute('data-active-tab');
//         if (activeTabId) {
//             const activeTab = this.tab.querySelector(`[id="${activeTabId}"]`);
//             if (activeTab) {
//                 activeTab.click();
//             }
//         }
//     }

//     tabClickHandler(e) {
//         // Hide All Tabpanes
//         this.tabPanels.forEach(panel => {
//             panel.hidden = true;
//         });

//         // Deselect Tab Button
//         this.tabButtons.forEach(button => {
//             button.setAttribute('aria-selected', 'false');
//         });

//         // Mark New Tab
//         e.currentTarget.setAttribute('aria-selected', 'true');

//         // Show New Tab
//         const { id } = e.currentTarget;

//         const currentTab = this.tabPanels.find(
//             panel => panel.getAttribute('aria-labelledby') === id
//         );

//         currentTab.hidden = false;

//         // Update URL parameter
//         const baseUrl = window.location.origin + window.location.pathname.split('#')[0]; // Extract base URL without tab identifier
//         const newUrl = baseUrl + '#' + id; // Construct new URL
//         window.history.replaceState({}, '', newUrl);
//     }

//     setCurrentTabFromURL() {
//         const path = window.location.pathname.split('#')[1]; // Extract tab identifier after '#'
//         if (path) {
//             const button = this.tab.querySelector(`[id="${path}"]`);
//             if (button) {
//                 button.click();
//             }
//         }
//     }
// }

// export default niteTab;












// class niteTab {
//     constructor(selector) {
//         this.tab = document.querySelector(selector);
//         this.tabButtons = this.tab.querySelectorAll('[role="tab"]');
//         this.tabPanels = Array.from(this.tab.querySelectorAll('[role="tabpanel"]'));

//         // Add event listeners to tab buttons
//         this.tabButtons.forEach(button => {
//             button.addEventListener('click', this.tabClickHandler.bind(this));
//         });

//         // Check for URL parameter and set current tab accordingly
//         this.setCurrentTabFromURL();
//     }

//     tabClickHandler(e) {
//         // Hide All Tabpanes
//         this.tabPanels.forEach(panel => {
//             panel.hidden = true;
//         });

//         // Deselect Tab Button
//         this.tabButtons.forEach(button => {
//             button.setAttribute('aria-selected', 'false');
//         });

//         // Mark New Tab
//         e.currentTarget.setAttribute('aria-selected', 'true');

//         // Show New Tab
//         const { id } = e.currentTarget;

//         const currentTab = this.tabPanels.find(
//             panel => panel.getAttribute('aria-labelledby') === id
//         );

//         currentTab.hidden = false;

//         // Update URL parameter
//         const baseUrl = window.location.origin + window.location.pathname.split('/').slice(0, -1).join('/'); // Extract base URL without tab identifier
//         const newUrl = baseUrl + '/' + id; // Construct new URL
//         window.history.replaceState({}, '', newUrl);
//     }

//     setCurrentTabFromURL() {
//         const path = window.location.pathname.split('/').pop(); // Extract last part of the path
//         if (path) {
//             const button = this.tab.querySelector(`[id="${path}"]`);
//             if (button) {
//                 button.click();
//             }
//         }
//     }
// }

// export default niteTab;




















// class niteTab {
//     constructor(selector) {
//         this.tab = document.querySelector(selector);
//         this.tabButtons = this.tab.querySelectorAll('[role="tab"]');
//         this.tabPanels = Array.from(this.tab.querySelectorAll('[role="tabpanel"]'));

//         // Add event listeners to tab buttons
//         this.tabButtons.forEach(button => {
//             button.addEventListener('click', this.tabClickHandler.bind(this));
//         });

//         // Check for URL parameter and set current tab accordingly
//         this.setCurrentTabFromURL();
//     }

//     tabClickHandler(e) {
//         // Hide All Tabpanes
//         this.tabPanels.forEach(panel => {
//             panel.hidden = true;
//         });

//         // Deselect Tab Button
//         this.tabButtons.forEach(button => {
//             button.setAttribute('aria-selected', 'false');
//         });

//         // Mark New Tab
//         e.currentTarget.setAttribute('aria-selected', 'true');

//         // Show New Tab
//         const { id } = e.currentTarget;

//         const currentTab = this.tabPanels.find(
//             panel => panel.getAttribute('aria-labelledby') === id
//         );

//         currentTab.hidden = false;

//         // Update URL parameter
//         const url = new URL(window.location.href);
//         url.searchParams.set('tab', id);
//         window.history.replaceState({}, '', url);
//     }

//     setCurrentTabFromURL() {
//         const urlParams = new URLSearchParams(window.location.search);
//         const tabParam = urlParams.get('tab');
//         if (tabParam) {
//             const button = this.tab.querySelector(`[id="${tabParam}"]`);
//             if (button) {
//                 button.click();
//             }
//         }
//     }
// }

// export default niteTab;






















// class niteTab {
//     constructor(selector) {
//         this.tab = document.querySelector(selector);
//         this.tabButtons = this.tab.querySelectorAll('[role="tab"]');
//         this.tabPanels = Array.from(this.tab.querySelectorAll('[role="tabpanel"]'));

//         // Add event listeners to tab buttons
//         this.tabButtons.forEach(button => {
//             button.addEventListener('click', this.tabClickHandler.bind(this));
//         });

//         // Check for URL hash and set current tab accordingly
//         this.setCurrentTabFromHash();
//     }

//     tabClickHandler(e) {
//         // Hide All Tabpanes
//         this.tabPanels.forEach(panel => {
//             panel.hidden = true;
//         });

//         // Deselect Tab Button
//         this.tabButtons.forEach(button => {
//             button.setAttribute('aria-selected', 'false');
//         });

//         // Mark New Tab
//         e.currentTarget.setAttribute('aria-selected', 'true');

//         // Show New Tab
//         const { id } = e.currentTarget;

//         const currentTab = this.tabPanels.find(
//             panel => panel.getAttribute('aria-labelledby') === id
//         );

//         currentTab.hidden = false;

//         // Update URL hash
//         window.location.hash = id;
//     }

//     setCurrentTabFromHash() {
//         const hash = window.location.hash.substr(1); // Remove '#'
//         const button = this.tab.querySelector(`[id="${hash}"]`);
//         if (button) {
//             button.click();
//         }
//     }
// }

// export default niteTab;































// class niteTab {
//     constructor(selector) {
//         this.tab = document.querySelector(selector);
//         this.tabButtons = this.tab.querySelectorAll('[role="tab"]');
//         this.tabPanels = Array.from(this.tab.querySelectorAll('[role="tabpanel"]'));

//         // Add event listeners to tab buttons
//         this.tabButtons.forEach(button => {
//             button.addEventListener('click', this.tabClickHandler.bind(this));
//         });

//         // Check for URL parameter and set current tab accordingly
//         this.setCurrentTabFromURL();
//     }

//     tabClickHandler(e) {
//         // Hide All Tabpanes
//         this.tabPanels.forEach(panel => {
//             panel.hidden = true;
//         });

//         // Deselect Tab Button
//         this.tabButtons.forEach(button => {
//             button.setAttribute('aria-selected', 'false');
//         });

//         // Mark New Tab
//         e.currentTarget.setAttribute('aria-selected', 'true');

//         // Show New Tab
//         const { id } = e.currentTarget;

//         const currentTab = this.tabPanels.find(
//             panel => panel.getAttribute('aria-labelledby') === id
//         );

//         currentTab.hidden = false;

//         // Update URL parameter
//         const url = new URL(window.location.href);
//         url.searchParams.set('tab', id);
//         window.history.replaceState({}, '', url);
//     }

//     setCurrentTabFromURL() {
//         const urlParams = new URLSearchParams(window.location.search);
//         const tabParam = urlParams.get('tab');
//         if (tabParam) {
//             const button = this.tab.querySelector(`[id="${tabParam}"]`);
//             if (button) {
//                 button.click();
//             }
//         }
//     }
// }

// export default niteTab;

























// class niteTab {
//     constructor(containerId) {
//         this.container = document.getElementById(containerId);
//         this.innerContainer = this.container.querySelector('.data-container');
//         this.tabLinks = this.container.querySelectorAll('.tab-link');
//         this.tabContent = this.container.querySelectorAll('.tab-pane');

//         // Get the data attributes from the inner container element
//         const containerData = this.innerContainer.dataset;
//         this.activeTab = containerData.activeTab;
//         this.activeClass = containerData.activeClass;
//         this.inactiveClass = containerData.inactiveClass;

//         this.init();
//     }

//     init() {
//         this.tabLinks.forEach((link, index) => {
//             link.addEventListener('click', () => this.handleTabClick(link, index));
//             // Set ARIA attributes for each tab link
//             link.setAttribute('role', 'tab');
//             link.setAttribute('aria-selected', index === 0 ? 'true' : 'false');
//             link.setAttribute('tabindex', index === 0 ? '0' : '-1');
//         });

//         if (window.location.hash) {
//             const initialTab = document.querySelector(window.location.hash);
//             if (initialTab) {
//                 this.showTab(window.location.hash);
//             }
//         } else {
//             this.showTab(this.activeTab);
//         }
//     }

//     showTab(tabId) {
//         this.tabContent.forEach(content => {
//             content.classList.add('hidden'); // Hide content using Tailwind CSS class
//         });

//         this.tabLinks.forEach(link => {
//             const tabLinkDataTab = link.getAttribute('data-tab');
//             if (tabLinkDataTab === tabId) {
//                 link.classList.remove(...this.inactiveClass.split(' '));
//                 link.classList.add(...this.activeClass.split(' '));
//                 link.setAttribute('aria-selected', 'true');
//                 link.setAttribute('tabindex', '0');
//             } else {
//                 link.classList.remove(...this.activeClass.split(' '));
//                 link.classList.add(...this.inactiveClass.split(' '));
//                 link.setAttribute('aria-selected', 'false');
//                 link.setAttribute('tabindex', '-1');
//             }
//         });

//         const innerTabContainer = document.querySelector(tabId);
//         if (innerTabContainer) {
//             innerTabContainer.classList.remove('hidden'); // Show inner tab content using Tailwind CSS class
//             innerTabContainer.querySelectorAll('.tab-pane').forEach(content => {
//                 content.classList.add('hidden'); // Hide inner tab content by default
//             });
//             const activeInnerTabLink = innerTabContainer.querySelector('[aria-selected="true"]');
//             if (activeInnerTabLink) {
//                 const innerTabId = activeInnerTabLink.getAttribute('data-tab');
//                 const innerTabContent = innerTabContainer.querySelector(innerTabId);
//                 if (innerTabContent) {
//                     innerTabContent.classList.remove('hidden'); // Show active inner tab content
//                 }
//             }
//         }
//     }

//     handleTabClick(link, index) {
//         const tabId = link.getAttribute('data-tab');
//         this.showTab(tabId);
//         window.location.hash = tabId;
//         link.focus();
//     }
// }




// export default niteTab;
























// class niteTab {
//     constructor(containerId) {
//         this.container = document.getElementById(containerId);
//         this.tabLinks = this.container.querySelectorAll('.tab-link');
//         this.tabContent = this.container.querySelectorAll('.tab-pane');

//         // Get the data attributes from the ul element
//         const tabsData = this.container.querySelector('ul').dataset;
//         this.activeTab = tabsData.activeTab;
//         this.activeClass = tabsData.activeClass;
//         this.inactiveClass = tabsData.inactiveClass;

//         this.init();
//     }

//     init() {
//         this.tabLinks.forEach((link, index) => {
//             link.addEventListener('click', () => this.handleTabClick(link, index));
//             // Set ARIA attributes for each tab link
//             link.setAttribute('role', 'tab');
//             link.setAttribute('aria-selected', index === 0 ? 'true' : 'false');
//             link.setAttribute('tabindex', index === 0 ? '0' : '-1');
//         });

//         if (window.location.hash) {
//             const initialTab = document.querySelector(window.location.hash);
//             if (initialTab) {
//                 this.showTab(window.location.hash);
//             }
//         } else {
//             this.showTab(this.activeTab);
//         }
//     }

//     showTab(tabId) {
//         this.tabContent.forEach(content => {
//             content.classList.add('hidden'); // Hide content using Tailwind CSS class
//         });

//         this.tabLinks.forEach(link => {
//             const tabLinkDataTab = link.getAttribute('data-tab');
//             if (tabLinkDataTab === tabId) {
//                 link.classList.remove(...this.inactiveClass.split(' '));
//                 link.classList.add(...this.activeClass.split(' '));
//                 link.setAttribute('aria-selected', 'true');
//                 link.setAttribute('tabindex', '0');
//             } else {
//                 link.classList.remove(...this.activeClass.split(' '));
//                 link.classList.add(...this.inactiveClass.split(' '));
//                 link.setAttribute('aria-selected', 'false');
//                 link.setAttribute('tabindex', '-1');
//             }
//         });

//         const innerTabContainer = document.querySelector(tabId);
//         if (innerTabContainer) {
//             innerTabContainer.classList.remove('hidden'); // Show inner tab content using Tailwind CSS class
//             innerTabContainer.querySelectorAll('.tab-pane').forEach(content => {
//                 content.classList.add('hidden'); // Hide inner tab content by default
//             });
//             const activeInnerTabLink = innerTabContainer.querySelector('[aria-selected="true"]');
//             if (activeInnerTabLink) {
//                 const innerTabId = activeInnerTabLink.getAttribute('data-tab');
//                 const innerTabContent = innerTabContainer.querySelector(innerTabId);
//                 if (innerTabContent) {
//                     innerTabContent.classList.remove('hidden'); // Show active inner tab content
//                 }
//             }
//         }
//     }

//     handleTabClick(link, index) {
//         const tabId = link.getAttribute('data-tab');
//         this.showTab(tabId);
//         window.location.hash = tabId;
//         link.focus();
//     }
// }

// export default niteTab;























// * Works as expected playing around to improve it further
// class niteTab {
//     constructor(containerId) {
//         this.container = document.getElementById(containerId);
//         this.tabLinks = this.container.querySelectorAll('.tab-link');
//         this.tabContent = this.container.querySelectorAll('.tab-pane');

//         // Get the data attributes from the ul element
//         const tabsData = this.container.querySelector('ul').dataset;
//         this.activeTab = tabsData.activeTab;
//         this.activeClass = tabsData.activeClass;
//         this.inactiveClass = tabsData.inactiveClass;

//         this.init();
//     }

//     init() {
//         this.tabLinks.forEach((link, index) => {
//             link.addEventListener('click', () => this.handleTabClick(link, index));
//             // Set ARIA attributes for each tab link
//             link.setAttribute('role', 'tab');
//             link.setAttribute('aria-selected', index === 0 ? 'true' : 'false');
//             link.setAttribute('tabindex', index === 0 ? '0' : '-1');
//         });

//         if (window.location.hash) {
//             const initialTab = document.querySelector(window.location.hash);
//             if (initialTab) {
//                 this.showTab(window.location.hash);
//             }
//         } else {
//             this.showTab(this.activeTab);
//         }
//     }

//     showTab(tabId) {
//         this.tabContent.forEach(content => {
//             content.classList.add('hidden'); // Hide content using Tailwind CSS class
//         });

//         this.tabLinks.forEach(link => {
//             const tabLinkDataTab = link.getAttribute('data-tab');
//             if (tabLinkDataTab === tabId) {
//                 link.classList.remove(...this.inactiveClass.split(' '));
//                 link.classList.add(...this.activeClass.split(' '));
//                 link.setAttribute('aria-selected', 'true');
//                 link.setAttribute('tabindex', '0');
//             } else {
//                 link.classList.remove(...this.activeClass.split(' '));
//                 link.classList.add(...this.inactiveClass.split(' '));
//                 link.setAttribute('aria-selected', 'false');
//                 link.setAttribute('tabindex', '-1');
//             }
//         });

//         const innerTabContainer = document.querySelector(tabId);
//         if (innerTabContainer) {
//             innerTabContainer.classList.remove('hidden'); // Show inner tab content using Tailwind CSS class
//             innerTabContainer.querySelectorAll('.tab-pane').forEach(content => {
//                 content.classList.add('hidden'); // Hide inner tab content by default
//             });
//             const activeInnerTabLink = innerTabContainer.querySelector('[aria-selected="true"]');
//             if (activeInnerTabLink) {
//                 const innerTabId = activeInnerTabLink.getAttribute('data-tab');
//                 const innerTabContent = innerTabContainer.querySelector(innerTabId);
//                 if (innerTabContent) {
//                     innerTabContent.classList.remove('hidden'); // Show active inner tab content
//                 }
//             }
//         }
//     }

//     handleTabClick(link, index) {
//         const tabId = link.getAttribute('data-tab');
//         this.showTab(tabId);
//         window.location.hash = tabId;
//         link.focus();
//     }
// }

// export default niteTab;








































// * Working code updating it to add nested tabs
// class niteTab {
//     constructor(containerId) {
//         this.container = document.getElementById(containerId);
//         this.tabLinks = this.container.querySelectorAll('.tab-link');
//         this.tabContent = this.container.querySelectorAll('.tab-pane');

//         // Get the data attributes from the ul element
//         const tabsData = this.container.querySelector('ul').dataset;
//         this.activeTab = tabsData.activeTab;
//         this.activeClass = tabsData.activeClass;
//         this.inactiveClass = tabsData.inactiveClass;

//         this.init();
//     }

//     init() {
//         this.tabLinks.forEach((link, index) => {
//             link.addEventListener('click', () => this.handleTabClick(link, index));
//             // Set ARIA attributes for each tab link
//             link.setAttribute('role', 'tab');
//             link.setAttribute('aria-selected', index === 0 ? 'true' : 'false');
//             link.setAttribute('tabindex', index === 0 ? '0' : '-1');
//         });

//         if (window.location.hash) {
//             const initialTab = document.querySelector(window.location.hash);
//             if (initialTab) {
//                 this.showTab(window.location.hash);
//             }
//         } else {
//             this.showTab(this.activeTab);
//         }
//     }

//     showTab(tabId) {
//         this.tabContent.forEach(content => {
//             content.style.display = 'none';
//         });

//         this.tabLinks.forEach(link => {
//             const tabLinkDataTab = link.getAttribute('data-tab');
//             if (tabLinkDataTab === tabId) {
//                 link.classList.remove(...this.inactiveClass.split(' '));
//                 link.classList.add(...this.activeClass.split(' '));
//                 link.setAttribute('aria-selected', 'true');
//                 link.setAttribute('tabindex', '0');
//             } else {
//                 link.classList.remove(...this.activeClass.split(' '));
//                 link.classList.add(...this.inactiveClass.split(' '));
//                 link.setAttribute('aria-selected', 'false');
//                 link.setAttribute('tabindex', '-1');
//             }
//         });

//         document.querySelector(tabId).style.display = 'block';
//     }

//     handleTabClick(link, index) {
//         const tabId = link.getAttribute('data-tab');
//         this.showTab(tabId);
//         window.location.hash = tabId;
//         // Set focus on the selected tab link
//         link.focus();
//     }
// }

// export default niteTab;
