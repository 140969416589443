/*********************************************************************************************
 * @function    : money
 * @purpose     : Formats a numerical value based on user settings for decimal precision,
 *                decimal and group separators, currency symbol positioning, and various 
 *                formatting options including spelling out the amount.
 * @param       : {number} numericalValue - The numerical value to be formatted.
 * @param       : {string} [options] - Formatting options (e.g., 'no_symbol', 'no_decimal',
 *                                     'plain', 'full', 'short', 'short_2_decimal', 
 *                                     'short_3_decimal', 'spell').
 * @returns     : {string} - The formatted monetary value as a string.
 * @version     : 1.2.1
 * @date        : 2024-07-26
 * @license     : All rights reserved. NITE - Hussain Attari
 * @disclaimer  : Some code might have be sourced from online or coded with the help of AI. 
 *                I have then made the necessary changes to adapt it for my specific use.
 *********************************************************************************************/
export default async function money(numericalValue, options) {
    const response = await fetch('/settings');
    const settings = await response.json();

    // Check if the settings don't exist or if the numericalValue is not a valid number.
    if (!settings || isNaN(numericalValue) || !isFinite(numericalValue || numericalValue === null || numericalValue === '')) {
        return numericalValue;
    }

    // Extract sign and convert to absolute value
    const sign = Math.sign(numericalValue);
    numericalValue = Math.abs(numericalValue);

    const decimalPrecision = settings.decimal_precision;
    const roundedNumerical = Math.round(numericalValue, decimalPrecision);

    // Format the decimal part with the correct number of decimal places
    const formattedDecimal = (roundedNumerical - Math.floor(roundedNumerical)).toFixed(decimalPrecision);

    numericalValue = numericalValue.toString().replace(settings.decimal_mark, '.');

    const formattedAmountValue = numericalValue.toLocaleString(undefined, {
        minimumFractionDigits: decimalPrecision,
        maximumFractionDigits: decimalPrecision,
        decimalSeparator: settings.decimal_mark,
        groupSeparator: ''
    });

    const decimalPosition = formattedAmountValue.indexOf(settings.decimal_mark);

    let numberOfDigits;

    if (decimalPosition === -1) {
        numberOfDigits = formattedAmountValue.replace(/[^0-9]/g, '').length;
    } else {
        const integerPart = formattedAmountValue.substring(0, decimalPosition);
        numberOfDigits = integerPart.replace(/[^0-9]/g, '').length;
    }

    const groupSeparator = settings.group_separator;

    const grouping = settings.grouping;

    let formattedIntegerPart = '';

    const length = numberOfDigits;

    if (grouping === 'millions') {
        for (let i = length - 1, count = 0; i >= 0; i--, count++) {
            if (count > 0 && count % 3 === 0) {
                formattedIntegerPart = groupSeparator + formattedIntegerPart;
            }
            formattedIntegerPart = formattedAmountValue[i] + formattedIntegerPart;
        }
    } else if (grouping === 'lakhs') {
        for (let i = length - 1, count = 0; i >= 0; i--, count++) {
            if (count === 3 || (count > 3 && (count - 3) % 2 === 0)) {
                formattedIntegerPart = groupSeparator + formattedIntegerPart;
            }
            formattedIntegerPart = formattedAmountValue[i] + formattedIntegerPart;
        }
    }

    // Add the extracted decimals to the formatted amount
    const formattedAmount = formattedIntegerPart + formattedDecimal.replace(/^0+/, '');
    const currencySymbol = settings.currency_symbol;
    const symbolPosition = settings.symbol_position;
    const currencyCode = settings.currency_code;
    const amountDecimalSeparator = settings.decimal_mark;
    const currencyName = settings.currency_name;
    const currencySubName = settings.currency_sub_name;
    let formattedResult;

    if (options) {
        switch (options) {
            // Return without currency symbol - Ex: 18,000.00
            case 'no_symbol':
                formattedResult = formattedAmount;
                if (sign === -1) {
                    formattedResult = '-' + formattedAmount;
                }

                return formattedResult;

                // Return with currency symbol but without decimals - Ex: ₹ 18,000
            case 'no_decimal':
                if (symbolPosition === 'pre') {
                    formattedResult = currencySymbol + formattedIntegerPart;
                } else if (symbolPosition === 'pre_with_space') {
                    formattedResult = currencySymbol + ' ' + formattedIntegerPart;
                } else if (symbolPosition === 'post') {
                    formattedResult = formattedIntegerPart + currencySymbol;
                } else if (symbolPosition === 'post_with_space') {
                    formattedResult = formattedIntegerPart + ' ' + currencySymbol;
                }

                if (sign === -1) {
                    formattedResult = '-' + formattedAmount;
                }

                return formattedResult;

                // Return without currency symbol and without decimals - Ex: 18,000
            case 'plain':
                formattedResult = formattedIntegerPart;
                if (sign === -1) {
                    formattedResult = '-' + formattedAmount;
                }
                return formattedResult;

                // Return with currency symbol, with decimal, and with currency code - Ex: ₹ 18,000.00 INR
            case 'full':
                formattedResult = `${currencySymbol} ${formattedAmount} ${currencyCode}`.trim();
                if (sign === -1) {
                    formattedResult = '-' + formattedAmount;
                }
                return formattedResult;

                // Return Ex: 18K
            case 'short':
                formattedResult = abbreviateNumber(numericalValue, 1, grouping);
                return formattedResult;

                // // Return Ex: ₹ 18K
                // case 'short_with_symbol':
                //     formattedResult = abbreviateNumber(numericalValue, 1, currencySymbol, grouping);

                // Return Ex: 18.00K
            case 'short_2_decimal':
                formattedResult = abbreviateNumber(numericalValue, 2, grouping);
                return formattedResult;

                // Return Ex: 18.000K
            case 'short_3_decimal':
                formattedResult = abbreviateNumber(numericalValue, 3, grouping);
                return formattedResult;

            case 'spell':
                if (grouping === 'millions') {
                    formattedResult = spellMillionAmount(numericalValue, amountDecimalSeparator, currencyName, currencySubName);
                    return formattedResult;
                } else {
                    formattedResult = spellLakhAmount(numericalValue, currencyName, currencySubName);
                    // console.log(formattedResult);
                    return formattedResult;
                }
        }
    } else {
        if (symbolPosition === 'pre') {
            formattedResult = currencySymbol + formattedAmount;
        } else if (symbolPosition === 'pre_with_space') {
            formattedResult = currencySymbol + ' ' + formattedAmount;
        } else if (symbolPosition === 'post') {
            formattedResult = formattedAmount + currencySymbol;
        } else if (symbolPosition === 'post_with_space') {
            formattedResult = formattedAmount + ' ' + currencySymbol;
        }
    }

    // After formatting, apply sign back to formatted result
    if (sign === -1) {
        formattedResult = '-' + formattedAmount;
    } else {
        formattedResult = formattedAmount;
    }

    // Return formatted result based on options and settings...
    return formattedResult;
}

/********************************************************************************************
* @function    : abbreviateNumber(value, precision, grouping)
* @purpose     : Abbreviates Numbers to short form
* @version     : 1.00
* @author      : Hussain Attari
* @license     : All rights reserved. This code may not be copied, modified, or distributed without the express permission of the author.
* @date        : 19 Jul 2024
* @param       : {value} [integer numeric or string]
* @returns     : {string} The abbreviated number string
*********************************************************************************************/
function abbreviateNumber(value, precision, grouping) {
    let newValue = Number(value); // Ensure value is converted to a number
    let amount = value;
    let suffixes;

    if (grouping === 'millions')
    {
        suffixes = ["", "K", "M", "B", "T"];

        let suffixNum = 0;
        while (newValue >= 1000) {
            newValue /= 1000;
            suffixNum++;
        }

        if (precision === 1)
        {
            newValue = newValue.toPrecision(3); // Return 3 significant [Integers] numbers.
            newValue = parseInt(newValue); // Returns only the integer value without decimals.
        }
        else if (precision === 2)
        {
            newValue = newValue.toFixed(2);
        }
        else if (precision === 3)
        {
            newValue = newValue.toFixed(3);
        }

        newValue += suffixes[suffixNum];
    }
    else
    {
        suffixes = ["", "K", "L", "Cr"];

        let suffixNum = 0;
        if (amount >= 1000 && amount <= 99999)
        {
            suffixNum = 1; // 'K' suffix for thousands
            if (precision === 1) {
                newValue = (amount / 1000).toFixed(precision);
                newValue = parseInt(newValue);
            } else {
                newValue = (amount / 1000).toFixed(precision);
            }
        }
        else if (amount >= 100000 && amount <= 9999999)
        {
            suffixNum = 2; // 'L' suffix for lakhs
            if (precision === 1) {
                newValue = (amount / 100000).toFixed(precision);
                newValue = parseInt(newValue);
            } else {
                newValue = (amount / 100000).toFixed(precision);
            }
        }
        else if (amount >= 10000000)
        {
            suffixNum = 3; // 'Cr' suffix for crores
            if (precision === 1) {
                newValue = (amount / 10000000).toFixed(precision);
                newValue = parseInt(newValue);
            } else {
                newValue = (amount / 10000000).toFixed(precision);
            }            
        }
        else
        {
            suffixNum = 0; // No suffix for values less than 1000
            // newValue = amount.toFixed(precision);
        }

        newValue += suffixes[suffixNum];
    }
    
    return (newValue);
}

/**
 * 
 * Better way of doing the spelling method check this reply on stackoverflow
 * https://stackoverflow.com/a/69243038
 * 
 */

/********************************************************************************************
* @function    : spellMillionAmount(amount, amountDecimalSeparator, currencyName, currencySubName, custom_join_character)
* @purpose     : Spell out an amount in million units with currency details
* @version     : 1.00
* @author      : Hussain Attari
* @license     : All rights reserved. This code may not be copied, modified, or distributed without the express permission of the author.
* @date        : 19 Jul 2024
* @param       : {string} amount - The amount to spell out
* @param       : {string} amountDecimalSeparator - The decimal separator used in the amount
* @param       : {string} currencyName - The name of the main currency
* @param       : {string} currencySubName - The name of the fractional currency
* @param       : {string} [custom_join_character] - Custom joining character between integer and decimal parts (optional)
* @returns     : {string} The spelled out amount with currency details
********************************************************************************************/
function spellMillionAmount(amount, amountDecimalSeparator, currencyName, currencySubName, custom_join_character) {
    // Find the position of the decimal separator
    const decimalIndex = amount.indexOf(amountDecimalSeparator);

    // Split the amount into integer and decimal parts based on the decimal separator position
    const r = amount.slice(0, decimalIndex);
    const p = amount.slice(decimalIndex + 1);

    // Pad the decimal part with zeros if necessary to ensure it has at least two digits
    // This line pads the p string with zeros using padEnd method to ensure it has at least two digits.
    // If p already has two or more digits, padEnd does nothing.
    const paddedDecimal = p.padEnd(2, '0');

    var and = custom_join_character || 'and';

    var int = spell_million_amount(r);
    var decimal = spell_million_amount(paddedDecimal);

    return int + ' ' + currencyName + ' ' + and + ' ' + decimal + ' ' + currencySubName + ' Only';
}

/**
 * Convert an integer to its words representation
 * 
 * @author McShaman (http://stackoverflow.com/users/788657/mcshaman)
 * @source http://stackoverflow.com/questions/14766951/convert-digits-into-words-with-javascript
 */
function spell_million_amount(amount, and) {
    var string = amount.toString(),
    units, tens, scales, start, end, chunks, chunksLen, chunk, ints, i, word, words;
    
    // var and = custom_join_character || 'and';

    /* Is number zero? */
    if (parseInt(string) === 0) {
        return 'zero';
    }

    /* Array of units as words */
    units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];

    /* Array of tens as words */
    tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

    /* Array of scales as words */
    scales = ['', 'Thousand', 'Million', 'Billion', 'trillion', 'quadrillion', 'quintillion', 'sextillion', 'septillion', 'octillion', 'nonillion', 'decillion', 'undecillion', 'duodecillion', 'tredecillion', 'quatttuor-decillion', 'quindecillion', 'sexdecillion', 'septen-decillion', 'octodecillion', 'novemdecillion', 'vigintillion', 'centillion'];

    /* Split user argument into 3 digit chunks from right to left */
    start = string.length;
    chunks = [];
    while (start > 0) {
        end = start;
        chunks.push(string.slice(start = Math.max(0, start - 3), end));
    }

    /* Check if function has enough scale words to be able to stringy the user argument */
    chunksLen = chunks.length;
    if (chunksLen > scales.length) {
        return '';
    }

    /* stringify each integer in each chunk */
    words = [];
    for (i = 0; i < chunksLen; i++) {
        chunk = parseInt(chunks[i]);

        if (chunk) {

            /* Split chunk into array of individual integers */
            ints = chunks[i].split('').reverse().map(parseFloat);

            /* If tens integer is 1, i.e. 10, then add 10 to units integer */
            if (ints[1] === 1) {
                ints[0] += 10;
            }

            /* Add scale word if chunk is not zero and array item exists */
            if ((word = scales[i])) {
                words.push(word);
            }

            /* Add unit word if array item exists */
            if ((word = units[ints[0]])) {
                words.push(word);
            }

            /* Add tens word if array item exists */
            if ((word = tens[ints[1]])) {
                words.push(word);
            }

            /* Add 'and' string after units or tens integer if: */
            if (ints[0] || ints[1]) {

                /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
                if (ints[2] || !i && chunksLen) {
                    words.push(and);
                }

            }

            /* Add hundreds word if array item exists */
            if ((word = units[ints[2]])) {
                words.push(word + ' hundred');
            }
        }
    }

    return words.reverse().join(' ');
}

/**
 * Convert an integer to its words representation
 * 
 * @author Hardik Thaker (https://stackoverflow.com/users/1635859/hardik-thaker)
 * @source https://stackoverflow.com/a/23105974
 */
function spellLakhAmount(amount, currencyName, currencySubName, custom_join_character) {
    var fraction = Math.round(frac(amount)*100);
    var decimal  = "";
    var and = custom_join_character || 'and';

    if (currencySubName !== '') {
        if(fraction > 0) {
            // Commenting this line as I don't need the word 'PAISE' to be printed along with the spelling.
            // f_text = "AND "+spell_lakh_amount(fraction)+" PAISE";
    
            decimal = spell_lakh_amount(fraction, and);
        } else {
            decimal = "zero ";
        }
    } else {
        if(fraction > 0) {
            // Commenting this line as I don't need the word 'PAISE' to be printed along with the spelling.
            // f_text = "AND "+spell_lakh_amount(fraction)+" PAISE";
    
            decimal = " point " + spell_lakh_amount(fraction, and);
        } else {
            decimal = "zero";
        }
    }

    // Commenthing this line as I don't need the words 'RUPEE' to be shown currently in the spelling
    // return spell_lakh_amount(amount)+" RUPEE "+f_text+" ONLY";

    return spell_lakh_amount(amount, and) + ' ' + currencyName + ' ' + and + ' ' + decimal + ' ' + currencySubName + ' ' + " Only";
}

function frac(f) {
    return f % 1;
}

function spell_lakh_amount(number, and) {
    if ((number < 0) || (number > 999999999)) 
    { 
        return "NUMBER OUT OF RANGE!";
    }
    
    var Gn = Math.floor(number / 10000000);     /* Crore */ 
    number -= Gn * 10000000;

    var kn = Math.floor(number / 100000);       /* lakhs */ 
    number -= kn * 100000; 
    
    var Hn = Math.floor(number / 1000);         /* thousand */ 
    number -= Hn * 1000; 
    
    var Dn = Math.floor(number / 100);          /* Tens (deca) */ 
    number = number % 100;                      /* Ones */ 
    
    var tn= Math.floor(number / 10); 
    
    var one=Math.floor(number % 10); 
    
    var res = ""; 

    if (Gn > 0) 
    { 
        res += (spell_lakh_amount(Gn) + " Crore"); 
    } 
    if (kn > 0) 
    { 
            res += (((res=="") ? "" : " ") + 
            spell_lakh_amount(kn) + " Lakh"); 
    } 
    if (Hn > 0) 
    { 
        res += (((res=="") ? "" : " ") +
            spell_lakh_amount(Hn) + " Thousand"); 
    } 

    if (Dn) 
    { 
        res += (((res=="") ? "" : " ") + 
            spell_lakh_amount(Dn) + " Hundred"); 
    } 


    var ones = Array('', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'); 
    var tens = Array('', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'); 

    if (tn > 0 || one > 0) 
    { 
        if (!(res=="")) 
        { 
            res += " " + and + " "; 
        } 
        if (tn < 2) 
        { 
            res += ones[tn * 10 + one]; 
        } 
        else 
        { 

            res += tens[tn];
            if (one > 0) 
            { 
                // Commenting this line as it is adding a '-' between then tens and ones like this 
                // Fifty-One instead of Fifty One
                // res += ("-" + ones[one]);
                res += (" " + ones[one]);
            } 
        } 
    }

    if (res=="")
    { 
        res = "zero"; 
    }
    
    return res;
}



/*********************************************************************************************
 * Changelog:
 * 
 * Version 1.2.1 (03-08-2024):
 * - Updated so that if the user provides a non numeric value then the value is returned
 *   without performing any function.
 * 
 * Version 1.2 (26-07-2024):
 * - Added 'spell' option to format the numerical value as a spelled-out amount.
 * - Enhanced currency and grouping logic for spell option.
 * - Modified sign handling to ensure negative values are correctly formatted.
 * 
 * Version 1.1 (24-07-2024):
 * - Added options parameter for various formatting styles.
 * - Included support for currency code, currency name, and sub-name.
 * - Added new formatting options for short number representation with different precisions.
 * 
 * Version 1.0 (21-07-2024):
 * - Initial implementation of the money function.
 * - Fetches user settings for decimal precision, decimal and group separators,
 *   and currency symbol positioning.
 * - Formats the numerical value accordingly and returns the formatted string.
 * 
 *********************************************************************************************/







































// export default async function money(numericalValue) {
//     const response = await fetch('/settings');
//     const settings = await response.json();

//     if (!settings) {
//         return numericalValue;
//     }

//     const decimalPrecision = settings.decimal_precision;
//     const roundedNumerical = Math.round(numericalValue, decimalPrecision);
    
//     // Format the decimal part with the correct number of decimal places
//     const formattedDecimal = (roundedNumerical - Math.floor(roundedNumerical)).toFixed(decimalPrecision);
    
//     numericalValue = numericalValue.toString().replace(settings.decimal_mark, '.');
    
//     const formattedAmountValue = numericalValue.toLocaleString(undefined, {
//         minimumFractionDigits: decimalPrecision,
//         maximumFractionDigits: decimalPrecision,
//         decimalSeparator: settings.decimal_mark,
//         groupSeparator: ''
//     });
    
//     const decimalPosition = formattedAmountValue.indexOf(settings.decimal_mark);
    
//     let numberOfDigits;
    
//     if (decimalPosition === -1) {
//         numberOfDigits = formattedAmountValue.replace(/[^0-9]/g, '').length;
//     } else {
//         const integerPart = formattedAmountValue.substring(0, decimalPosition);
//         numberOfDigits = integerPart.replace(/[^0-9]/g, '').length;
//     }
    
//     const groupSeparator = settings.group_separator;
    
//     const grouping = settings.grouping;
    
//     let formattedIntegerPart = '';
    
//     const length = numberOfDigits;
    
//     if (grouping === 'millions') {
//         for (let i = length - 1, count = 0; i >= 0; i--, count++) {
//             if (count > 0 && count % 3 === 0) {
//                 formattedIntegerPart = groupSeparator + formattedIntegerPart;
//             }
//             formattedIntegerPart = formattedAmountValue[i] + formattedIntegerPart;
//         }
//     } else if (grouping === 'lakhs') {
//         for (let i = length - 1, count = 0; i >= 0; i--, count++) {
//             if (count === 3 || (count > 3 && (count - 3) % 2 === 0)) {
//                 formattedIntegerPart = groupSeparator + formattedIntegerPart;
//             }
//             formattedIntegerPart = formattedAmountValue[i] + formattedIntegerPart;
//         }
//     }
    
//     // Add the extracted decimals to the formatted amount
//     const formattedAmount = formattedIntegerPart + formattedDecimal.replace(/^0+/, '');
//     const currencySymbol = settings.currency_symbol;
//     const symbolPosition = settings.symbol_position;
    
//     if (symbolPosition === 'pre') {
//         return currencySymbol + formattedAmount;
//     } else if (symbolPosition === 'pre_with_space') {
//         return currencySymbol + ' ' + formattedAmount;
//     } else if (symbolPosition === 'post') {
//         return formattedAmount + currencySymbol;
//     } else if (symbolPosition === 'post_with_space') {
//         return formattedAmount + ' ' + currencySymbol;
//     }
// }


















// Tried using Promise instead of async / await but didn't work
// export default function money(numericalValue) {
//     // Fetch settings and numerical formatting in parallel
//     return Promise.all([
//         fetch('/settings').then(response => response.json()),
//         Promise.resolve(numericalValue)
//     ]).then(([settings, value]) => {
//         if (!settings) {
//             return value;
//         }

//         const decimalPrecision = settings.decimal_precision;
//         const roundedNumerical = Math.round(value, decimalPrecision);

//         // Format the decimal part with the correct number of decimal places
//         const formattedDecimal = (roundedNumerical - Math.floor(roundedNumerical)).toFixed(decimalPrecision);

//         const numericalValue = value.toString().replace(settings.decimal_mark, '.');

//         const formattedAmountValue = numericalValue.toLocaleString(undefined, {
//             minimumFractionDigits: decimalPrecision,
//             maximumFractionDigits: decimalPrecision,
//             decimalSeparator: settings.decimal_mark,
//             groupSeparator: ''
//         });

//         const decimalPosition = formattedAmountValue.indexOf(settings.decimal_mark);
//         let numberOfDigits;

//         if (decimalPosition === -1) {
//             numberOfDigits = formattedAmountValue.replace(/[^0-9]/g, '').length;
//         } else {
//             const integerPart = formattedAmountValue.substring(0, decimalPosition);
//             numberOfDigits = integerPart.replace(/[^0-9]/g, '').length;
//         }

//         const groupSeparator = settings.group_separator;
//         const grouping = settings.grouping;
//         let formattedIntegerPart = '';
//         const length = numberOfDigits;

//         if (grouping === 'millions') {
//             for (let i = length - 1, count = 0; i >= 0; i--, count++) {
//                 if (count > 0 && count % 3 === 0) {
//                     formattedIntegerPart = groupSeparator + formattedIntegerPart;
//                 }
//                 formattedIntegerPart = formattedAmountValue[i] + formattedIntegerPart;
//             }
//         } else if (grouping === 'lakhs') {
//             for (let i = length - 1, count = 0; i >= 0; i--, count++) {
//                 if (count === 3 || (count > 3 && (count - 3) % 2 === 0)) {
//                     formattedIntegerPart = groupSeparator + formattedIntegerPart;
//                 }
//                 formattedIntegerPart = formattedAmountValue[i] + formattedIntegerPart;
//             }
//         }

//         // Add the extracted decimals to the formatted amount
//         const formattedAmount = formattedIntegerPart + formattedDecimal.replace(/^0+/, '');
//         const currencySymbol = settings.currency_symbol;
//         const symbolPosition = settings.symbol_position;

//         if (symbolPosition === 'pre') {
//             return currencySymbol + formattedAmount;
//         } else if (symbolPosition === 'pre_with_space') {
//             return currencySymbol + ' ' + formattedAmount;
//         } else if (symbolPosition === 'post') {
//             return formattedAmount + currencySymbol;
//         } else if (symbolPosition === 'post_with_space') {
//             return formattedAmount + ' ' + currencySymbol;
//         }
//     });
// }




























// * Works flawlessly. Improving it so that i don't have to use async / await
// * As using async await here is causing issues in the code where I use it as i have to compulsarily use it like this await money(numericalValue)
// * Instead trying to use only money(numericalValue)

// export default async function money(numericalValue) {
//     const response = await fetch('/settings');
//     const settings = await response.json();

//     if (!settings) {
//         return numericalValue;
//     }

//     const decimalPrecision = settings.decimal_precision;
//     const roundedNumerical = Math.round(numericalValue, decimalPrecision);
    
//     // Format the decimal part with the correct number of decimal places
//     const formattedDecimal = (roundedNumerical - Math.floor(roundedNumerical)).toFixed(decimalPrecision);
    
//     numericalValue = numericalValue.toString().replace(settings.decimal_mark, '.');
    
//     const formattedAmountValue = numericalValue.toLocaleString(undefined, {
//         minimumFractionDigits: decimalPrecision,
//         maximumFractionDigits: decimalPrecision,
//         decimalSeparator: settings.decimal_mark,
//         groupSeparator: ''
//     });
    
//     const decimalPosition = formattedAmountValue.indexOf(settings.decimal_mark);
    
//     let numberOfDigits;
    
//     if (decimalPosition === -1) {
//         numberOfDigits = formattedAmountValue.replace(/[^0-9]/g, '').length;
//     } else {
//         const integerPart = formattedAmountValue.substring(0, decimalPosition);
//         numberOfDigits = integerPart.replace(/[^0-9]/g, '').length;
//     }
    
//     const groupSeparator = settings.group_separator;
    
//     const grouping = settings.grouping;
    
//     let formattedIntegerPart = '';
    
//     const length = numberOfDigits;
    
//     if (grouping === 'millions') {
//         for (let i = length - 1, count = 0; i >= 0; i--, count++) {
//             if (count > 0 && count % 3 === 0) {
//                 formattedIntegerPart = groupSeparator + formattedIntegerPart;
//             }
//             formattedIntegerPart = formattedAmountValue[i] + formattedIntegerPart;
//         }
//     } else if (grouping === 'lakhs') {
//         for (let i = length - 1, count = 0; i >= 0; i--, count++) {
//             if (count === 3 || (count > 3 && (count - 3) % 2 === 0)) {
//                 formattedIntegerPart = groupSeparator + formattedIntegerPart;
//             }
//             formattedIntegerPart = formattedAmountValue[i] + formattedIntegerPart;
//         }
//     }
    
//     // Add the extracted decimals to the formatted amount
//     const formattedAmount = formattedIntegerPart + formattedDecimal.replace(/^0+/, '');
//     const currencySymbol = settings.currency_symbol;
//     const symbolPosition = settings.symbol_position;
    
//     if (symbolPosition === 'pre') {
//         return currencySymbol + formattedAmount;
//     } else if (symbolPosition === 'pre_with_space') {
//         return currencySymbol + ' ' + formattedAmount;
//     } else if (symbolPosition === 'post') {
//         return formattedAmount + currencySymbol;
//     } else if (symbolPosition === 'post_with_space') {
//         return formattedAmount + ' ' + currencySymbol;
//     }
// }




































// * Got the decimal part but the group separator is being added where not required and more than once

// export default async function money(numericalValue) {
//     const response = await fetch('/settings');
//     const settings = await response.json();

//     if (!settings) {
//         return numericalValue;
//     }

//     const decimalPrecision = settings.decimal_precision;
//     const roundedNumerical = Math.round(numericalValue * 10 ** decimalPrecision) / 10 ** decimalPrecision;

//     // Format the decimal part with the correct number of decimal places
//     const formattedDecimal = (roundedNumerical - parseInt(roundedNumerical)).toFixed(decimalPrecision).replace('.', settings.decimal_mark);

//     numericalValue = numericalValue.toString().replace(settings.decimal_mark, '.');
//     // Format the numerical value
//     const formattedAmount = numericalValue
//         .replace(/\B(?=(\d{3})+(?!\d))/g, settings.group_separator)
//         .replace(settings.decimal_mark, '');

//     const decimalPosition = formattedAmount.indexOf(settings.decimal_mark);
//     let numberOfDigits;

//     if (decimalPosition === -1) {
//         numberOfDigits = formattedAmount.replace(/[^0-9]/g, '').length;
//     } else {
//         const integerPart = formattedAmount.substring(0, decimalPosition);
//         numberOfDigits = integerPart.replace(/[^0-9]/g, '').length;
//     }

//     let formattedIntegerPart = '';
//     const length = numberOfDigits;

//     if (settings.grouping === 'millions') {
//         for (let i = length - 1, count = 0; i >= 0; i--, count++) {
//             if (count > 0 && count % 3 === 0) {
//                 formattedIntegerPart = settings.group_separator + formattedIntegerPart;
//             }
//             formattedIntegerPart = formattedAmount[i] + formattedIntegerPart;
//         }
//     } else if (settings.grouping === 'lakhs') {
//         for (let i = length - 1, count = 0; i >= 0; i--, count++) {
//             if (count === 3 || (count > 3 && (count - 3) % 2 === 0)) {
//                 formattedIntegerPart = settings.group_separator + formattedIntegerPart;
//             }
//             formattedIntegerPart = formattedAmount[i] + formattedIntegerPart;
//         }
//     }

//     // Add the extracted decimals to the formatted amount
//     const formattedAmountResult = formattedIntegerPart + formattedDecimal.replace(/^0+/, '');

//     const currencySymbol = settings.currency_symbol;
//     const symbolPosition = settings.symbol_position;

//     if (symbolPosition === 'pre') {
//         return currencySymbol + formattedAmountResult;
//     } else if (symbolPosition === 'pre_with_space') {
//         return currencySymbol + ' ' + formattedAmountResult;
//     } else if (symbolPosition === 'post') {
//         return formattedAmountResult + currencySymbol;
//     } else if (symbolPosition === 'post_with_space') {
//         return formattedAmountResult + ' ' + currencySymbol;
//     }
// }















// * Working code.
// * Everything works except that the decimals are not being added to the formattedAmount

// export default async function money(numericalValue) {
//     const response = await fetch('/settings');
//     const settings = await response.json();

//     if (!settings) {
//         return numericalValue;
//     }

//     const decimalPrecision = settings.decimal_precision;
//   const roundedNumerical = Math.round(numericalValue * 10 ** decimalPrecision) / 10 ** decimalPrecision;

//   // Format the decimal part with the correct number of decimal places
//   const formattedDecimal = (roundedNumerical - Math.floor(roundedNumerical)).toFixed(decimalPrecision).substring(2);

//   numericalValue = numericalValue.toString().replace(settings.decimal_mark, '.');
//   // Format the numerical value
//   const formattedAmount = numericalValue
//     .replace(/\B(?=(\d{3})+(?!\d))/g, settings.group_separator)
//     .replace(settings.decimal_mark, '');

//   const decimalPosition = formattedAmount.indexOf(settings.decimal_mark);
//   let numberOfDigits;

//   if (decimalPosition === -1) {
//     numberOfDigits = formattedAmount.replace(/[^0-9]/g, '').length;
//   } else {
//     const integerPart = formattedAmount.substring(0, decimalPosition);
//     numberOfDigits = integerPart.replace(/[^0-9]/g, '').length;
//   }

//   let formattedIntegerPart = '';
//   const length = numberOfDigits;

//   if (settings.grouping === 'millions') {
//     for (let i = length - 1, count = 0; i >= 0; i--, count++) {
//       if (count > 0 && count % 3 === 0) {
//         formattedIntegerPart = settings.group_separator + formattedIntegerPart;
//       }
//       formattedIntegerPart = formattedAmount[i] + formattedIntegerPart;
//     }
//   } else if (settings.grouping === 'lakhs') {
//     for (let i = length - 1, count = 0; i >= 0; i--, count++) {
//       if (count === 3 || (count > 3 && (count - 3) % 2 === 0)) {
//         formattedIntegerPart = settings.group_separator + formattedIntegerPart;
//       }
//       formattedIntegerPart = formattedAmount[i] + formattedIntegerPart;
//     }
//   }

//   // Add the extracted decimals to the formatted amount
//   const formattedAmountResult = formattedIntegerPart + formattedDecimal.replace(/^0+/, '');

//   const currencySymbol = settings.currency_symbol;
//   const symbolPosition = settings.symbol_position;

//   if (symbolPosition === 'pre') {
//     return currencySymbol + formattedAmountResult;
//   } else if (symbolPosition === 'pre_with_space') {
//     return currencySymbol + ' ' + formattedAmountResult;
//   } else if (symbolPosition === 'post') {
//     return formattedAmountResult + currencySymbol;
//   } else if (symbolPosition === 'post_with_space') {
//     return formattedAmountResult + ' ' + currencySymbol;
//   }
// }























// export default async function money(numericalValue) {
//     const response = await fetch('/settings');
//     const settings = await response.json();

//     if (!settings) {
//         return numericalValue;
//     }

//     const decimalPrecision = settings.decimal_precision;
//     const roundedNumerical = Math.round(numericalValue * 10 ** decimalPrecision) / 10 ** decimalPrecision;

//     // Format the rounded numerical value
//     const formattedRounded = numberFormat(roundedNumerical, decimalPrecision, settings.decimal_mark, settings.group_separator);

//     // Format the original numerical value
//     const formattedOriginal = numberFormat(numericalValue, decimalPrecision, settings.decimal_mark, settings.group_separator);

//     // Extract and format the decimal part
//     const formattedDecimal = (roundedNumerical - Math.floor(roundedNumerical)).toFixed(decimalPrecision).substring(2);

//     const decimalPosition = formattedOriginal.indexOf(settings.decimal_mark);
//     const integerPart = decimalPosition === -1 ? formattedOriginal : formattedOriginal.slice(0, decimalPosition);

//     const numberOfDigits = integerPart.replace(/[^0-9]/g, '').length;

//     const groupSeparator = settings.group_separator;
//     const grouping = settings.grouping;

//     let formattedIntegerPart = '';
//     let length = numberOfDigits;

//     if (grouping === 'millions') {
//         for (let i = length - 1, count = 0; i >= 0; i--, count++) {
//             if (count > 0 && count % 3 === 0) {
//                 formattedIntegerPart = groupSeparator + formattedIntegerPart;
//             }
//             formattedIntegerPart = formattedOriginal[i] + formattedIntegerPart;
//         }
//     } else if (grouping === 'lakhs') {
//         for (let i = length - 1, count = 0; i >= 0; i--, count++) {
//             if (count === 3 || (count > 3 && (count - 3) % 2 === 0)) {
//                 formattedIntegerPart = groupSeparator + formattedIntegerPart;
//             }
//             formattedIntegerPart = formattedOriginal[i] + formattedIntegerPart;
//         }
//     }

//     // Add the extracted decimals to the formatted amount
//     const formattedAmountResult = formattedIntegerPart + formattedDecimal.replace(/^0+/, '');

//     const currencySymbol = settings.currency_symbol;
//     const symbolPosition = settings.symbol_position;

//     if (symbolPosition === 'pre') {
//         return currencySymbol + formattedAmountResult;
//     } else if (symbolPosition === 'pre_with_space') {
//         return currencySymbol + ' ' + formattedAmountResult;
//     } else if (symbolPosition === 'post') {
//         return formattedAmountResult + currencySymbol;
//     } else if (symbolPosition === 'post_with_space') {
//         return formattedAmountResult + ' ' + currencySymbol;
//     }
// }

// // Custom numberFormat function
// function numberFormat(value, decimalPrecision, decimalMark, groupSeparator) {
//     return parseFloat(value).toFixed(decimalPrecision).replace('.', decimalMark).replace(/\B(?=(\d{3})+(?!\d))/g, groupSeparator);
// }
















// export default async function money(numericalValue) {
//     const response = await fetch('/settings');
//     const settings = await response.json();

//     if (!settings) {
//         return numericalValue;
//     }

//     const decimalPrecision = settings.decimal_precision;
//     const roundedNumerical = Math.round(numericalValue * 10 ** decimalPrecision) / 10 ** decimalPrecision;

//     // Format the decimal part with the correct number of decimal places
//     const formattedDecimal = (roundedNumerical - Math.floor(roundedNumerical)).toFixed(decimalPrecision).substring(2);

//     numericalValue = numericalValue.toString().replace(settings.decimal_mark, '.');
//     const formattedAmount = parseFloat(numericalValue).toFixed(decimalPrecision).replace('.', settings.decimal_mark);

//     const decimalPosition = formattedAmount.indexOf(settings.decimal_mark);
//     const integerPart = decimalPosition === -1 ? formattedAmount : formattedAmount.slice(0, decimalPosition);

//     const numberOfDigits = integerPart.replace(/[^0-9]/g, '').length;

//     const groupSeparator = settings.group_separator;
//     const grouping = settings.grouping;

//     let formattedIntegerPart = '';
//     let length = numberOfDigits;

//     if (grouping === 'millions') {
//         for (let i = length - 1, count = 0; i >= 0; i--, count++) {
//             if (count > 0 && count % 3 === 0) {
//                 formattedIntegerPart = groupSeparator + formattedIntegerPart;
//             }
//             formattedIntegerPart = formattedAmount[i] + formattedIntegerPart;
//         }
//     } else if (grouping === 'lakhs') {
//         for (let i = length - 1, count = 0; i >= 0; i--, count++) {
//             if (count === 3 || (count > 3 && (count - 3) % 2 === 0)) {
//                 formattedIntegerPart = groupSeparator + formattedIntegerPart;
//             }
//             formattedIntegerPart = formattedAmount[i] + formattedIntegerPart;
//         }
//     }

//     // Add the extracted decimals to the formatted amount
//     const formattedAmountResult = formattedIntegerPart + formattedDecimal.replace(/^0+/, '');

//     const currencySymbol = settings.currency_symbol;
//     const symbolPosition = settings.symbol_position;

//     if (symbolPosition === 'pre') {
//         return currencySymbol + formattedAmountResult;
//     } else if (symbolPosition === 'pre_with_space') {
//         return currencySymbol + ' ' + formattedAmountResult;
//     } else if (symbolPosition === 'post') {
//         return formattedAmountResult + currencySymbol;
//     } else if (symbolPosition === 'post_with_space') {
//         return formattedAmountResult + ' ' + currencySymbol;
//     }
// }




























// export default async function money(numericalValue) {
//     const response = await fetch('/settings');
//     const settings = await response.json();

//     if (!settings) {
//         return numericalValue;
//     }

//     const decimalPrecision = settings['decimal_precision'];
//     const roundedNumerical = Math.round((numericalValue * Math.pow(10, decimalPrecision)));
//     const formattedDecimal = (numericalValue - Math.floor(numericalValue)).toFixed(decimalPrecision);
//     const formattedAmount = numericalValue.toFixed(decimalPrecision);

//     const decimalPosition = formattedAmount.indexOf('.');

//     if (decimalPosition === -1) {
//         const integerPart = formattedAmount;
//         const numberOfDigits = integerPart.length;
//     } else {
//         const integerPart = formattedAmount.slice(0, decimalPosition);
//         const numberOfDigits = integerPart.length;
//     }

//     const groupSeparator = settings['group_separator'];
//     const grouping = settings['grouping'];

//     let formattedIntegerPart = '';
//     let length = numberOfDigits;

//     if (grouping === 'millions') {
//         for (let i = length - 1; i >= 0; i--) {
//             if (length > 0 && length % 3 === 0) {
//                 formattedIntegerPart += groupSeparator;
//             }
//             formattedIntegerPart += formattedAmount[i];
//         }
//     } else if (grouping === 'lakhs') {
//         for (let i = length - 1; i >= 0; i--) {
//             if (length > 3 || ((length - 3) % 2 === 0)) {
//                 formattedIntegerPart += groupSeparator;
//             }
//             formattedIntegerPart += formattedAmount[i];
//         }
//     }

//     const currencySymbol = settings['currency_symbol'];
//     const symbolPosition = settings['symbol_position'];

//     if (symbolPosition === 'pre') {
//         formattedAmount = currencySymbol + formattedAmount;
//     } else if (symbolPosition === 'pre_with_space') {
//         formattedAmount = currencySymbol + ' ' + formattedAmount;
//     } else if (symbolPosition === 'post') {
//         formattedAmount += currencySymbol;
//     } else if (symbolPosition === 'post_with_space') {
//         formattedAmount += ' ' + currencySymbol;
//     }

//     return formattedAmount;
// }
